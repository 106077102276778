import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'


const Services = () => {
  const icons = useStaticQuery(graphql`query Icons($height: Int = 50, $width: Int = 50) {
      hay1: file(name: {eq: "hooi"}) {
        childImageSharp {
          gatsbyImageData(
            width: $width
            height: $height
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      hay2: file(name: {eq: "hay_1"}) {
        childImageSharp {
          gatsbyImageData(
            width: $width
            height: $height
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      coconut: file(name: {eq: "coconut"}) {
        childImageSharp {
          gatsbyImageData(
            width: $width
            height: $height
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      seed: file(name: {eq: "private-label-tag"}) {
        childImageSharp {
          gatsbyImageData(
            width: $width
            height: $height
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }`
  )

  return (
    <section className='container' id='services'>
      <div className='features-1 py-3 py-md-5'>
        <div className='row'>
          <div className='col-md-10 ml-auto mr-auto'>
            <h2 className='title mb-2 mt-2 mt-md-3'>
              Welkom bij Verpakkingsbedrijf Veldhuizen Perspak!
            </h2>
            <hr></hr>
            <h5 className='description'>
              <p>
                Wij zijn gespecialiseerd in het verpakken van hooi en bodembedekkers voor kleine huisdieren.
                Grote volumes worden verwerkt tot handzame verpakkingen voor de huisdierenbranche, variërend van 1 tot 14 kg.
                We hebben diverse productielijnen om aan de brede vraag te voldoen. Samen wordt er gekeken naar de producteigenschappen
                en de wens en mogelijkheden welke verpakking het meest geschikt is voor het product.
              </p>
            </h5>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <div className='info pt-3 pt-md-5'>
              <div className={`d-flex align-items-center justify-content-center`}>
                <GatsbyImage image={getImage(icons.hay1)}
                  alt='' />
              </div>
              <h4 className='info-title'>Hooi</h4>
              <p>
                Met zorg wordt gekeken naar mooi hooi van goede kwaliteit. Het grootste deel wordt vanuit Zuid-Duitsland geïmporteerd bij voor ons vertrouwde adressen.
              </p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='info pt-3 pt-md-5'>
              <div className={`d-flex align-items-center justify-content-center`}>
                <GatsbyImage image={getImage(icons.coconut)}
                  alt='' />
              </div>
              <h4 className='info-title'>Kokos</h4>
              <p>
                Kokos voeren we in uit Sri Lanka, waar met zorg verschillende formaten kokoschips worden gemaakt.
              </p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='info pt-3 pt-md-5'>
              <div className={`d-flex align-items-center justify-content-center`}>
                <GatsbyImage image={getImage(icons.hay2)}
                  alt='' />
              </div>
              <h4 className='info-title'>Stro</h4>
              <p>
                Verschillende soorten stro worden bij ons ingepakt, waaronder tarwe-, gerste- en erwtenstro. Het grootste deel komt via contacten uit Frankrijk.
              </p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='info pt-3 pt-md-5'>
              <div className={`d-flex align-items-center justify-content-center`}>
                <GatsbyImage image={getImage(icons.seed)}
                  alt='' />
              </div>
              <h4 className='info-title'>Copackaging</h4>
              <p>
                Wij pakken ook producten voor derden in met onze moderne en flexibele productielijnen.
                Er zijn talloze mogelijkheden in de formaatkeuze, het mixen van grondstoffen en het al dan niet gebruikmaken van private label.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
