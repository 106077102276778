import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import { iconCredits, footer } from './footer.module.scss'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query data {
      logo: file(name: {eq: "logo_white"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 120
            layout: FIXED
            placeholder: BLURRED
            pngOptions: {}
          )
        }
      }
    }  
  `)

  return (
    <footer className={`position-relative py-5 mt-md-5 mt-5 bg-primary-dark ${footer}`}>
      <div className='container'>
        <div className='row w-md-75'>
          <div className='col-12 col-md-4 d-flex justify-content-center justify-content-md-start'>
            <GatsbyImage
              image={getImage(data.logo)}
              alt='Veldhuizen Perspak'
            />
          </div>
          <div className='col-12 col-md-4 text-white text-md-left py-3 py-md-0'>
            <div className='font-weight-bold text-secondary-light header-font text-medium'>
              Contact
            </div>
            <ul>
              <li className='d-block' key='address'>
                Wencopperweg 54
              </li>
              <li className='d-block' key='postal-city'>
                3771 PP Barneveld
              </li>
              <li className='d-block' key='phone'>
                <a href='tel:+31629212599' className='p-0 large-text text-white'>
                  06 - 292 125 99
                </a>
              </li>
              <li className='d-block' key='email'>
                <a href='mailto:info@perspak.nl' className='p-0' style={{ textTransform: 'uppercase' }}>
                  info@perspak.nl
                </a>
              </li>
            </ul>
          </div>
          <div className='col-12 col-md-4 text-white text-md-left py-3 py-md-0'>
            <div className='font-weight-bold text-secondary-light header-font text-medium'>
              Links
            </div>
            <ul>
              <li className='d-block' key='link-services'>
                <a href='#services' className='py-2 py-md-0'>
                  Producten &amp; diensten
                </a>
              </li>
              <li className='d-block' key='link-about-us'>
                <a href='#about-us' className='py-2 py-md-0'>
                  Over ons
                </a>
              </li>
              <li className='d-block' key='link-contact'>
                <a href='#contact' className='py-2 py-md-0'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* absolutely position icon credits */}
      <div className={`${iconCredits} text-white w-100 text-center text-md-right pr-md-3`}>
        Icons made by <a href='https://www.freepik.com' title='Freepik' className='text-white'>Freepik </a>
        from
        <a href='https://www.flaticon.com/' title='Flaticon' className='text-white' style={{ textTransform: 'lowercase' }}>
          &nbsp;www.flaticon.com
        </a>
      </div>
    </footer >
  )
}

export default Footer
