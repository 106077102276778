import * as React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Footer from '../components/footer'
import AboutUs from '../components/aboutUs'
import Contact from '../components/contact'
import Services from '../components/services'
// import CoPackaging from '../components/coPackaging'
import Seo from '../components/seo'

const IndexPage = () => {
  const title = 'Home'
  const description = 'Wij verpakken grote volumes hooi, stro, katoen, kokos en nog veel meer verschillende producten in handzame balen. Eigenaar Gilbert Veldhuizen heeft zijn roots in een generaties oud ondernemersgeslacht. Klantgerichtheid en servicegerichtheid is hem daarom met de paplepel ingegoten.'
  const openGraph = { title, description, type: 'website' }

  return (
    <Layout pageTitle={'Home'} headerIsTransparent>
      <Seo
        title={title}
        description={description}
        openGraph={openGraph} />
      <Hero />
      <Services />
      {/* <CoPackaging /> */}
      <AboutUs />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage
