import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

import { aboutUs, aboutUsTitle, tractor, straw, sun } from './aboutUs.module.scss'
import Overlay from './overlay'

const getBackground = imageData => convertToBgImage(getImage(imageData))

const AboutUs = () => {
  const data = useStaticQuery(graphql`query AboutUs {
      straw: file(name: {eq: "hay_1"}) {
        childImageSharp {
          gatsbyImageData(
            width: 160
            height: 160
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      tractor: file(name: {eq: "hooi_single_shaded"}) {
        childImageSharp {
          gatsbyImageData(
            width: 120
            height: 170
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      sun: file(name: {eq: "sun"}) {
        childImageSharp {
          gatsbyImageData(
            width: 130
            height: 130
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      grass: file(name: {eq: "grass"}) {
        childImageSharp {
          gatsbyImageData(
            width: 408
            height: 300
            layout: FULL_WIDTH
            placeholder: BLURRED
            jpgOptions: {quality: 50}
          )
        }
      }
      agriculture: file(name: {eq: "agriculture"}) {
        childImageSharp {
          gatsbyImageData(
            width: 410
            height: 260
            layout: FULL_WIDTH
            placeholder: BLURRED
            jpgOptions: {quality: 50}
          )
        }
      }
      trucks: file(name: {eq: "trucks"}) {
        childImageSharp {
          gatsbyImageData(
            width: 725
            height: 265
            layout: FULL_WIDTH
            placeholder: BLURRED
            jpgOptions: {quality: 50}
          )
        }
      }
    }`
  )

  return (
    <section className={`mt-5 position-relative ${aboutUs}`} id='about-us'>
      <GatsbyImage image={getImage(data.tractor)}
        alt=''
        className={`${tractor} position-absolute d-none d-md-block`} />
      <GatsbyImage image={getImage(data.straw)}
        alt=''
        className={`${straw} position-absolute d-none d-md-block`} />
      <GatsbyImage image={getImage(data.sun)}
        alt=''
        className={`${sun} position-absolute d-none d-md-block`} />

      <div className='container'>
        <div className='py-5'>
          <div className='row'>
            <div className='col-md-8 ml-auto mr-auto text-center'>
              <h2 className={`mt-0 mt-md-3 title ${aboutUsTitle}`}>
                Zo werken wij
              </h2>
              <hr></hr>
              <p>
                De interesse en bekwaamheid om een bedrijf te starten en te doen groeien is van jongs af aan al meegegeven aan Gilbert Veldhuizen, de eigenaar van Veldhuizen Perspak die het bedrijf oprichtte in 1999.
              </p>
            </div>
            <div className='col-md-6'>
              <BackgroundImage {...getBackground(data.grass)}
                className='card card-raised card-background my-2 my-md-5'>
                <Overlay />
                <div className='card-body px-3'>
                  <h6 className='card-category text-info'>Kwaliteitszorg</h6>
                  <h3 className='card-title pb-2 text-secondary-light'>Kwaliteit begint met de wil om dingen goed te doen</h3>
                  <p className='font-weight-bold text-white'>
                    Die wil is in ons bedrijf voor de volle 100% aanwezig. Wij zijn altijd op zoek naar nieuwe manieren om onze kwaliteit te verbeteren.
                  </p>
                  <p className='font-weight-bold text-white'>
                    Hiermee leveren wij producten naar volle klanttevredenheid.
                  </p>
                </div>
              </BackgroundImage>
            </div>
            <div className='col-md-6 mt-md-5'>
              <BackgroundImage {...getBackground(data.agriculture)}
                className='card card-raised card-background mt-md-5'>
                <Overlay />
                <div className='position-absolute w-100 h-100' style={{ backgroundColor: 'black', opacity: 0.5 }}></div>
                <div className='card-body px-3'>
                  <h6 className='card-category text-info'>Milieu</h6>
                  <h3 className='card-title text-secondary-light'>
                    Oog voor het milieu
                  </h3>
                  <p className='font-weight-bold text-white'>
                    We maken zoveel mogelijk gebruik van re-used materialen. Met de juiste bewerking en afwerking worden deze materialen gemoderniseerd tot de hedendaagse standaard.
                  </p>
                  <p className='font-weight-bold text-white'>
                    Daarnaast doen we onderzoek naar gerecycled plastic voor het verpakkingsmateriaal en de mogelijkheden van groene energie.
                  </p>
                </div>
              </BackgroundImage>
            </div>
            <div className='col-md-10'>
              <BackgroundImage {...getBackground(data.trucks)}
                className='card card-raised card-background mt-2 ml-md-5'>
                <Overlay opacity={0.6} />
                <div className='card-body px-3 mx-2' style={{ maxWidth: '100vw' }}>
                  <h6 className='card-category text-info'>Logistiek</h6>
                  <h3 className='card-title text-secondary-light'>We zijn gemakkelijk bereikbaar</h3>
                  <p className='font-weight-bold text-white'>
                    Ons pand is centraal in het land gevestigd, aan de rand van het bedrijventerrein Harselaar-Zuid in Barneveld. Vanaf de snelweg A1 staat u binnen enkele minuten bij ons voor de deur.
                  </p>
                  <p className='font-weight-bold text-white'>
                    Voor het laden en lossen op ons terrein kan er gebruikgemaakt worden van een laadput en hebben we verschillende heftrucks en een verreiker ter beschikking. Ook zeecontainers lossen wij snel en efficiënt en
                    voor producten die los in de container liggen, hebben wij speciale opslagbunkers. Daarmee kunnen we de lading van een aantal containers tijdelijk bewaren.
                  </p>
                </div>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
