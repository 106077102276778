import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

import { logo } from './hero.module.scss'
import Overlay from './overlay'

const getBackground = imageData => convertToBgImage(getImage(imageData))

export const Hero = () => {

  const imageData = useStaticQuery(graphql`query Images {
      logo: file(name: {eq: "logo_white"}) {
        childImageSharp {
          gatsbyImageData(width: 580, height: 250, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      background: file(name: {eq: "farm"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 1080
            jpgOptions: {quality: 50}
            placeholder: BLURRED
          )
        }
      }
    }`
  )

  return (
    <BackgroundImage Tag='section'
      {...getBackground(imageData.background)}
      preserveStackingContext
      className='page-header d-flex justify-content-center'
      id='hero'>
      <Overlay opacity={0.3} />
      <GatsbyImage image={getImage(imageData.logo)}
        className={`position-relative ${logo}`}
        alt='' />
    </BackgroundImage>
  )
}

export default Hero
