import * as React from 'react'
import { map } from './contact.module.scss'

const Contact = () => {
  return (
    <section className='container py-2 py-md-5' id='contact'>
      <div className='row'>
        <div className='col-md-5'>
          <h2 className='title text-center text-md-left'>Neem contact met ons op</h2>
          <hr></hr>
          <h5 className='description text-center text-md-left'>
            Heeft u interesse? Dan kunt u ons bereiken via een van de onderstaande kanalen.
          </h5>
          <div className='info info-horizontal pt-3 pb-1 mx-0'>
            <div className='icon icon-primary'>
              <i className='material-icons'>pin_drop</i>
            </div>
            <div className='description'>
              <h4 className='info-title'>Onze adresgegevens</h4>
              <p>
                Veldhuizen Perspak<br />
                Wencopperweg 54<br />
                3771 PP Barneveld
              </p>
            </div>
          </div>
          <div className='info info-horizontal py-1 mx-0'>
            <div className='icon icon-primary'>
              <i className='material-icons'>phone</i>
            </div>
            <div className='description'>
              <h4 className='info-title'>Ons telefoonnummer</h4>
              <p>
                <a href='tel:+31629212599' className='text-black'>
                  06 - 292 125 99
                </a>
              </p>
            </div>
          </div>
          <div className='info info-horizontal py-1 mx-0'>
            <div className='icon icon-primary'>
              <i className='material-icons'>mail</i>
            </div>
            <div className='description'>
              <h4 className='info-title'>Ons e-mailadres</h4>
              <p>
                <a href='mailto:info@perspak.nl' className='text-black'>
                  info@perspak.nl
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className='col d-flex align-items-center pt-5'>
          <div className='w-100 h-100'>
            <iframe title='OpenStreetMap' className={`w-100 h-100 ${map}`} frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' src='https://www.openstreetmap.org/export/embed.html?bbox=5.621346831321717%2C52.16205551016929%2C5.624350905418397%2C52.1641631510787&amp;layer=mapnik' style={{ border: '1px solid black' }}></iframe><br />
            <small><a href='https://www.openstreetmap.org/#map=19/52.16311/5.62285'>View Larger Map</a></small>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
