import * as React from 'react'

import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getSrc } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'


const SEO = ({ description, lang, meta, title, openGraph, keywords, sayYesToRobots = true }) => {
  const { site, logo } = useStaticQuery(graphql`query Site {
      site {
        siteMetadata {
          title
          description
        }
      }
      logo: file(name: {eq: "logo - color"}) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
    }`
  )

  const metaKeywords = keywords || 'Veldhuizen Perspak'
  const metaDescription = description || site.siteMetadata.description

  const ogTitle = openGraph?.title || title
  const ogDescription = openGraph?.description || metaDescription
  const ogImage = openGraph?.image || getSrc(logo)
  
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'og:title',
          content: ogTitle,
        },
        {
          name: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          name: 'og:image',
          content: ogImage,
        },
        {
          name: 'og:description',
          content: ogDescription,
        },
        {
          name: 'og:url',
          content: 'https://www.perspak.nl',
        },
        {
          name: 'og:type',
          content: openGraph?.type || 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'robots',
          content: `${sayYesToRobots ? 'index' : 'noindex'}, follow`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'nl',
  meta: [],
  description: '',
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
