import * as React from 'react'
import { defaultBackground } from './overlay.module.scss'


const Overlay = ({ className }) => {
  return (
    <div className={`position-absolute w-100 h-100 ${className ?? defaultBackground}`}></div>
  )
}

export default Overlay
