import React, { useState, useEffect } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import { toggle, themeNav } from './layout.module.scss'

const Layout = ({ pageTitle, children, headerIsTransparent }) => {
  const imageData = useStaticQuery(graphql`query Logo {
      logo: file(name: {eq: "logo_transparant_notext_small"}) {
        childImageSharp {
          gatsbyImageData(width: 60, height: 50, layout: FIXED, placeholder: BLURRED)
        }
      }
    }`
  )

  // navbar tranparency
  const [navTransparent, setTransparency] = useState(headerIsTransparent)

  useEffect(() => {
    // setting event listener up
    const updateNavbarTransparency = () => setTransparency(headerIsTransparent && window.scrollY <= 200)
    window.addEventListener('scroll', updateNavbarTransparency)

    // and dispose of it
    const cleanup = () => window.removeEventListener('scroll', updateNavbarTransparency)
    return cleanup
  })

  return (
    <main>
      <title>{pageTitle}</title>
      <nav className={`navbar navbar-expand-lg fixed-top px-4 ${themeNav} ${navTransparent ? 'bg-transparent' : 'bg-primary-dark'}`}>
        <a className='navbar-brand py-0' href='#hero'>
          <GatsbyImage
            image={getImage(imageData.logo)}
            alt='Veldhuizen Perspak'
            className='h-100' />
        </a>
        <button
          className={`navbar-toggler ${toggle}`}
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>

          <span className='navbar-toggler-icon'></span>
          <span className='navbar-toggler-icon'></span>
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item' key='link-services'>
              <a className='nav-link' href='#services'>
              Producten &amp; Diensten
              </a>
            </li>
            <li className='nav-item' key='link-copackaging'>
              <a className='nav-link' href='#copackaging'>
                Copackaging
              </a>
            </li>
            <li className='nav-item' key='link-about-us'>
              <a className='nav-link' href='#about-us'>
                Over ons
              </a>
            </li>
            <li className='nav-item' key='link-contact'>
              <a className='nav-link' href='#contact'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {children}
    </main>
  )
}

export default Layout
